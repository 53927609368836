import React from "react";
import { Box } from "rebass/styled-components";
import Container from "../components/UI/Container";
import Page from "../templates/Page";
import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { title: { eq: "legals" } }) {
          frontmatter {
            name
            url
            address
            publication
            url
            mail
            phone
          }
        }
      }
    `
  );
  const legals = markdownRemark.frontmatter || {};
  return (
    <Page title="Politique de confidentialité">
      <Box py={10}>
        <Container>
          <h1 className="title-underlined text-upper">Politique de confidentialité</h1>

          <h3>Introduction</h3>
          <p>
            La présente politique de confidentialité définit et vous informe de la manière dont{" "}
            {legals.name} utilise et protège les informations que vous nous transmettez, le cas
            échéant, lorsque vous utilisez le présent site accessible à partir de l’URL suivante :{" "}
            {legals.url}
            (ci-après le « Site »).
          </p>
          <p>
            Veuillez noter que cette politique de confidentialité est susceptible d’être modifiée ou
            complétée à tout moment par {legals.name}, notamment en vue de se conformer à toute
            évolution législative, réglementaire, jurisprudentielle ou technologique. Dans un tel
            cas, la date de sa mise à jour sera clairement identifiée en tête ou en pied de page de
            la présente politique. Ces modifications engagent l’Utilisateur dès leur mise en ligne.
            Il convient par conséquent que l’Utilisateur consulte régulièrement la présente
            politique de confidentialité et d’utilisation des cookies afin de prendre connaissance
            de ses éventuelles modifications.
          </p>
          <h2>I. Données personnelles</h2>

          <p>
            D’une manière générale, il vous est possible de visiter le Site de
            {legals.name} sans communiquer aucune information personnelle vous concernant. En toute
            hypothèse, vous êtes en aucune manière obligé de transmettre ces informations à{" "}
            {legals.name}
          </p>
          <p>
            Néanmoins, en cas de refus, il se peut que vous ne puissiez pas bénéficier de certaines
            informations ou services que vous avez demandé. A ce titre en effet, {legals.name} peut
            être amené dans certains cas à vous demander de renseigner :
          </p>
          <ul>
            <li>
              <p>Nom</p>
            </li>
            <li>
              <p>Prénom</p>
            </li>
            <li>
              <p>Adresse mail</p>
            </li>
            <li>
              <p>Nom de votre entreprise</p>
            </li>
            <li>
              <p>Numéro de téléphone</p>
            </li>
          </ul>
          <p>
            (ci-après vos « Informations Personnelles »). En fournissant ces informations, vous
            acceptez expressément qu’elles soient traitées par
            {legals.name}, aux fins indiquées au point 2 ci-dessous ainsi qu’aux fins rappelées à la
            fin de chaque formulaire.
          </p>
          <p>
            Conformément au Règlement Général sur la Protection des Données (RGPD) adopté par le
            Parlement européen le 14 avril 2016, et à la Loi Informatique et Libertés du 6 janvier
            1978 modifiée, {legals.name} vous informe des points suivants :
          </p>
          <ul>
            <li>
              <h3>1. Identité du responsable du traitement</h3>
            </li>
          </ul>
          <p>
            Le responsable du traitement est la société {legals.name}, {legals.address}
          </p>
          <p>Tél : {legals.phone}</p>
          <p>Mail : {legals.mail}</p>
          <h3>2. Finalités du traitement</h3>
          <p>{legals.name} est susceptible de traiter vos Informations Personnelles :</p>
          <p>
            (a) aux fins de vous fournir les informations ou les services que vous avez demandé
            (notamment : Formulaire de contact
          </p>
          <p>
            (b) aux fins de recueillir des informations nous permettant d’améliorer notre Site, nos
            produits et services (notamment par le biais de cookies).
          </p>
          <h3>Collecte des Informations Personnelles</h3>
          <p>Nous collectons les renseignements suivants :</p>
          <ul>
            <li>
              <p>Nom</p>
            </li>
            <li>
              <p>Prénom</p>
            </li>
            <li>
              <p>Adresse mail</p>
            </li>
            <li>
              <p>Nom de votre entreprise</p>
            </li>
            <li>
              <p>Numéro de téléphone</p>
            </li>
          </ul>

          <p>
            Les renseignements personnels que nous collectons sont recueillis au travers de
            formulaires et grâce à l&apos;interactivité établie entre vous et notre site Web.
          </p>
          <h3>Formulaires et interactivité</h3>
          <p>Vos renseignements personnels sont collectés par le biais de formulaire, à savoir :</p>
          <ul>
            <li>
              <p>Formulaire de contact</p>
            </li>
          </ul>
          <p>
            Vos renseignements sont également collectés par le biais de l&apos;interactivité pouvant
            s&apos;établir entre vous et notre site Web et ce dans le but de :
          </p>
          <ul>
            <li>
              <p>Statistiques</p>
            </li>
          </ul>

          <h3>3. Destinataires</h3>
          <p>
            Seul {legals.name} est destinataire de vos Informations Personnelles. Celles-ci, que ce
            soit sous forme individuelle ou agrégée, ne sont jamais transmises à un tiers,
            nonobstant les sous-traitants auxquels {legals.name}
            fait appel (vous trouverez de plus amples informations à leur sujet au point 7
            ci-dessous). Ni {legals.name}, ni aucun de ses sous-traitants, ne procèdent à la
            commercialisation des données personnelles des visiteurs et Utilisateurs de son Site.
          </p>
          <h3>4. Durée de conservation</h3>
          <p>
            Vos Informations Personnelles sont conservées par {legals.name} uniquement pour le temps
            correspondant à la finalité de la collecte tel qu’indiqué en 2 ci-dessus qui ne saurait
            en tout état de cause excéder 24 mois.
          </p>
          <h3>5. Droits Informatique et Libertés et RGPD</h3>
          <p>Vos droits en matière d’accès à vos données personnelles.</p>
          <p>
            Vous avez le droit d’accéder à vos données personnelles et de demander qu’elles soient
            rectifiées, complétées ou mises à jour. Vous pouvez également demander l’effacement de
            vos données ou vous opposer à leur traitement, à condition de justifier d’un motif
            légitime.
          </p>
          <p>
            Vous pouvez demander à exercer votre droit à la portabilité de vos données, c’est-à-dire
            le droit de recevoir les données personnelles que vous nous avez fournies dans un format
            structuré, couramment utilisé et le droit de transmettre ces données à un autre
            responsable de traitement.
          </p>
          <p>
            Vous pouvez enfin formuler des directives relatives à la conservation, à l’effacement et
            à la communication de vos données à caractère personnel après votre décès.
          </p>
          <p>
            Vous pouvez exercer vos droits auprès du responsable des données personnelles de{" "}
            {legals.name} :
          </p>
          <p>Par courrier</p>
          <ul>
            <li>
              <p>
                {legals.name} : {legals.address}
              </p>
            </li>
          </ul>
          <p>Par mail</p>

          <ul>
            <li>
              <p>{legals.mail}</p>
            </li>
          </ul>
          <p>
            Avant de répondre à votre demande, nous sommes susceptibles de vérifier votre identité
            et/ou vous demander de nous fournir davantage d’informations pour répondre à votre
            demande. Nous nous efforcerons de donner suite à votre demande dans un délai raisonnable
            et, en tout état de cause, dans les délais fixés par la loi.
          </p>
          <p>
            En cas de réponse insatisfaisante, vous pouvez introduire une réclamation auprès de la
            Commission nationale de l’informatique et des libertés (CNIL) :
            <a href="https://www.cnil.fr/fr/plaintes" target="blank">
              https://www.cnil.fr/fr/plaintes
            </a>
          </p>
          <h4>Droit d’accès et de communication des données</h4>
          <p>Vous avez la faculté d’accéder aux Informations Personnelles qui vous concernent.</p>
          <p>
            Cependant, en raison de l’obligation de sécurité et de confidentialité dans le
            traitement des données à caractère personnel qui incombe à{legals.name}, vous êtes
            informé que votre demande sera traitée sous réserve que vous rapportiez la preuve de
            votre identité, notamment par la production d’un scan de votre titre d’identité valide
            (en cas de demande par notre formulaire électronique dédié) ou d’une photocopie signée
            de votre titre d’identité valide (en cas de demande adressée par écrit).
          </p>
          <p>
            {legals.name} vous informe qu’il sera en droit, le cas échéant, de s’opposer aux
            demandes manifestement abusives (de par leur nombre, leur caractère répétitif ou
            systématique).
          </p>
          <p>
            Pour vous aider dans votre démarche, notamment si vous désirez exercer votre droit
            d’accès par le biais d’une demande écrite à l’adresse postale mentionnée au point 1,
            vous trouverez
            <a href="https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces">
              en cliquant sur le lien suivant
            </a>
            un modèle de courrier élaboré par la Commission Nationale de l’Informatique et des
            Libertés (la « CNIL »).
          </p>
          <p>
            <a href="https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces">
              https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces
            </a>
          </p>
          <h4>Droit de rectification des données</h4>
          <p>
            Au titre de ce droit, la législation vous habilite à demander la rectification, la mise
            à jour, le verrouillage ou encore l’effacement des données vous concernant qui peuvent
            s’avérer le cas échéant inexactes, erronées, incomplètes ou obsolètes.
          </p>
          <p>
            Egalement, vous pouvez définir des directives générales et particulières relatives au
            sort des données à caractère personnel après votre décès. Le cas échéant, les héritiers
            d’une personne décédée peuvent exiger de prendre en considération le décès de leur
            proche et/ou de procéder aux mises à jour nécessaires.
          </p>
          <p>
            Pour vous aider dans votre démarche, notamment si vous désirez exercer, pour votre
            propre compte ou pour le compte de l’un de vos proches décédé, votre droit de
            rectification par le biais d’une demande écrite à l’adresse postale mentionnée au point
            1, vous trouverez
            <a href="https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees">
              en cliquant sur le lien suivant
            </a>
            un modèle de courrier élaboré par la CNIL.
          </p>
          <p>
            <a href="https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees">
              https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees
            </a>
          </p>
          <h4>Droit d’opposition</h4>
          <p>
            L’exercice de ce droit n’est possible que dans l’une des deux situations suivantes :
          </p>
          <ol>
            <li>
              <p>Lorsque l’exercice de ce droit est fondé sur des motifs légitimes ; ou</p>
            </li>
            <li>
              <p>
                Lorsque l’exercice de ce droit vise à faire obstacle à ce que les données
                recueillies soient utilisées à des fins de prospection commerciale.
              </p>
            </li>
          </ol>
          <p>
            Pour vous aider dans votre démarche, notamment si vous désirez exercer votre droit
            d’opposition par le biais d’une demande écrite adressée à l’adresse postale indiquée au
            point 1, vous trouverez
            <a href="https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees">
              en cliquant sur le lien suivant
            </a>
            un modèle de courrier élaboré par la CNIL.
          </p>
          <p>
            <a href="https://www.cnil.fr/fr/modele/courrier/supprimer-des-informations-vous-concernant-dun-site-internet">
              https://www.cnil.fr/fr/modele/courrier/supprimer-des-informations-vous-concernant-dun-site-internet
            </a>
          </p>
          <h3>6. Délais de réponse</h3>
          <p>
            {legals.name} s’engage à répondre à votre demande d’accès, de rectification ou
            d’opposition ou toute autre demande complémentaire d’informations dans un délai
            raisonnable qui ne saurait dépasser 1 mois à compter de la réception de votre demande.
          </p>
          <h3>7. Prestataires habilités et transfert vers un pays tiers de l’Union Européenne</h3>
          <p>
            {legals.name} vous informe qu’il a recours à ses prestataires habilités pour faciliter
            le recueil et le traitement des données que vous nous avez communiqué. Ces prestataires
            peuvent être situés en dehors de l’Union Européenne et ont communication des données
            recueillies par le biais du ou des formulaires présents sur le Site.
          </p>
          <p>
            {legals.name} s’est préalablement assuré de la mise en œuvre par ses prestataires de
            garanties adéquates et du respect de conditions strictes en matière de confidentialité,
            d’usage et de protection des données. Tout particulièrement, la vigilance s’est portée
            sur l’existence d’un fondement légal pour effectuer un quelconque transfert de données
            vers un pays tiers. A ce titre, l’un de nos prestataires, Google Analytics est conforme
            avec les cadres légaux en lien avec le transfert de données tels que que les EU-US et
            Swiss-US Privacy Shield Framework. De plus, l’hébergement de {legals.url} est pris en
            charge par La Fabrik, 33 ter rue Jean-Jaurès - 98 800 - Nouvelle Calédonie, Tél. : + 687
            26 39 04, Mail : contact[at]la-fabrik.nc. L’hébergeur est La Fabrik dont les serveurs
            sont présents en Australie.
          </p>
          <h3>8. Plainte auprès de l’autorité compétente</h3>
          <p>
            Si vous considérez que {legals.name} ne respecte pas ses obligations au regard de vos
            Informations Personnelles, vous pouvez adresser une plainte ou une demande auprès de
            l’autorité compétente. En France, l’autorité compétente est la CNIL à laquelle vous
            pouvez adresser une demande par voie électronique en cliquant sur le lien suivant :
            <a href="https://www.cnil.fr/fr/plaintes/internet">
              https://www.cnil.fr/fr/plaintes/internet
            </a>
            .
          </p>

          <h3>Sécurité</h3>

          <p>
            Les renseignements personnels que nous collectons sont conservés dans un environnement
            sécurisé. Les personnes travaillant pour nous sont tenues de respecter la
            confidentialité de vos informations.
          </p>
          <p>
            Pour assurer la sécurité de vos renseignements personnels, nous avons recours aux
            mesures suivantes :
          </p>
          <ul>
            <li>
              <p>Protocole SSL (Secure Sockets Layer)</p>
            </li>
            <li>
              <p>Back office avec Identifiant / mot de passe requis</p>
            </li>
            <li>
              <p>Pare-feu (Firewalls)</p>
            </li>
          </ul>
          <p>
            Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les
            dernières innovations technologiques permettant d&apos;assurer la confidentialité de vos
            actions. Toutefois, comme aucun mécanisme n&apos;offre une sécurité maximale, une part
            de risque est toujours présente lorsque l&apos;on utilise Internet pour transmettre des
            renseignements personnels.
          </p>
          <h3>Législation</h3>
          <p>
            Nous nous engageons à respecter les dispositions législatives énoncées dans la GDPR de
            l’Union Européenne.
          </p>

          <h2>II. Politique relative aux cookies</h2>
          <h3>Données statistiques</h3>
          <p>
            Afin de mieux connaître les centres d&apos;intérêt des visiteurs du site et en vue de
            son amélioration, nous pouvons être amenés à mesurer le nombre de pages vues, le nombre
            de visites, ainsi que l&apos;activité des visiteurs sur le présent site, et leur
            fréquence de retour. A cet effet, la technologie des &quot;cookies&quot; peut être
            utilisée sur des parties de ce site.
          </p>
          <p>
            Par ailleurs, la société {legals.name} peut procéder également à l&apos;analyse de la
            fréquentation du présent site Internet à partir de l&apos;exploitation des données de
            connexion.
          </p>
          <p>
            Pour vous opposer à l&apos;enregistrement de cookies ou être prévenu avant
            d&apos;accepter les cookies, nous vous recommandons la lecture de la rubrique
            d&apos;aide de votre navigateur qui vous précisera la marche à suivre.
          </p>
          <h3>Qu&apos;est ce qu&apos;un Cookie et à quoi sert-il ?</h3>
          <p>
            Un cookie (ou témoin de connexion) est un fichier texte susceptible d&apos;être
            enregistré, sous réserve de vos choix, dans un espace dédié du disque dur de votre
            terminal (ordinateur, tablette ...) à l&apos;occasion de la consultation d&apos;un
            service en ligne grâce à votre logiciel de navigation.
          </p>
          <p>
            Il est transmis par le serveur d&apos;un site internet à votre navigateur. A chaque
            cookie est attribué un identifiant anonyme. Le fichier cookie permet à son émetteur
            d&apos;identifier le terminal dans lequel il est enregistré pendant la durée de validité
            ou d&apos;enregistrement du cookie concerné. Un cookie ne permet pas de remonter à une
            personne physique.
          </p>
          <p>
            Lorsque vous consultez le site de {legals.name}, nous pouvons être amenés à installer,
            sous réserve de votre choix, différents cookies et notamment des cookies publicitaires.
          </p>
          <h3>Liens vers des sites web externe à {legals.name} et des applications tierces</h3>
          <p>
            Pour vous permettre d&apos;interagir avec d&apos;autres sites Web sur lesquels vous
            possédez peut-être des comptes (tels que Facebook et d&apos;autres sites de médias
            sociaux) ou de vous joindre à des communautés sur ces sites, nous pouvons vous fournir
            des liens ou intégrer des applications tierces vous permettant de vous connecter, de
            publier du contenu ou de vous joindre à des communautés à partir de nos sites Web.
          </p>
          <p>
            Nous pouvons également vous fournir des liens vers des sites Web externe à {legals.name}
            .
          </p>
          <p>
            L&apos;utilisation de ces liens et applications est soumise aux politiques de
            confidentialité des sites tiers avec lesquelles vous devez vous familiariser avant
            d&apos;utiliser les liens ou les applications.
          </p>
          <p>
            {legals.name} ne saurait être tenue responsable de leurs pratiques en matière de
            confidentialité ou de leur contenu.
          </p>
          <h3>Liste des cookies utilisés par {legals.name}</h3>
          <p>Voici la liste que nous utilisons majoritairement sur notre site :</p>

          <ul>
            <li>
              <p>Google Analytics</p>
            </li>
          </ul>

          <h3>Combien de temps les cookies sont-ils actifs ?</h3>
          <p>
            Les cookies arrivent en général à échéance au plus tard 13 mois après avoir été placé
            sur l’ordinateur ou le terminal mobile de l’utilisateur.
          </p>
          <h3>Paramètres des cookies</h3>
          <p>
            Si vous refusez que des cookies soient installés sur votre ordinateur ou votre terminal
            mobile, vous pouvez configurer votre navigateur pour limiter ou exclure l’utilisation de
            cookies. Votre navigateur peut vous fournir des informations relatives au paramétrage
            des cookies. Vous pouvez notamment configurer votre navigateur afin de vous alerter dès
            qu’un site internet visité utilise des cookies. Vous pouvez également configurer votre
            navigateur pour refuser tous les cookies ou accepter certains types de cookies. D’une
            façon générale, vous pouvez désactiver la fonctionnalité de cookie sur votre navigateur,
            sans affecter votre l’ergonomie de votre visite sur le site de {legals.name}.
          </p>
          <p>
            Notez bien que ces paramètres ne sont valables que pour les pages créées et détenues par{" "}
            {legals.name}. Lorsque nous intégrons des liens vers des sites internet externes et que
            vous cliquez sur ces liens, vous quittez notre site : dès lors, les paramètres et les
            politiques applicables à notre site Web ne s&apos;appliquent plus. Il vous appartient
            alors de consulter sur ledit site, la politique relative à la protection des données et
            aux cookies.
          </p>
          <p>
            Si vous ne souhaitez pas recevoir de cookies de la part de notre site Internet, vous
            pouvez configurer votre navigateur afin de les refuser ou qu&apos;il vous avertisse
            lorsque vous en recevez un, vous demandant alors si vous acceptez ou non le placement
            d’un tel cookie. Vous pouvez également paramétrer votre navigateur afin de désactiver
            les cookies. Pour comprendre comment faire, veuillez consulter l’onglet « Outils » de
            votre navigateur, ou toute autre dénomination similaire.
          </p>
          <p>
            Nous vous recommandons toutefois de ne pas désactiver nos cookies. Gardez à
            l&apos;esprit que si vous bloquez, éteignez, ou rejetez nos cookies, certaines pages de
            notre site Internet ne pourront plus s&apos;afficher correctement ou vous ne serez plus
            en mesure d&apos;utiliser certains services vous demandant de vous connecter.
          </p>
          <p>
            Si vous refusez d’avoir nos cookies placés sur votre ordinateur, vous devrez dès lors
            les désactiver. Pour ce faire, veuillez suivre les instructions appropriées ci-dessous
            en fonction de votre navigateur :
          </p>
          <ul>
            <li>
              <p>
                Si vous utilisez le navigateur
                <a href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies">
                  Internet Explorer.
                </a>
              </p>
            </li>
            <li>
              <p>
                Si vous utilisez le navigateur
                <a href="https://support.mozilla.org/fr/kb/autoriser-bloquer-cookies-preferences-sites?redirectlocale=fr&amp;redirectslug=activer-desactiver-cookies">
                  Firefox
                </a>
                .
              </p>
            </li>
            <li>
              <p>
                Si vous utilisez le navigateur
                <a href="https://support.apple.com/kb/ph5042?locale=fr_FR">Safari</a>.
              </p>
            </li>
            <li>
              <p>
                Si vous utilisez le navigateur
                <a href="https://support.google.com/chrome/answer/95647?hl=fr">Google Chrome.</a>
              </p>
            </li>
          </ul>
        </Container>
      </Box>
    </Page>
  );
};
